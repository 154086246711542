import React from 'react'
import {
  useAuthorizedFreightsContext
} from '../../../../contexts/AuthorizedFreightsContext'

const DownloadPDFButton = ({ evidenciaUid, name }) => {
  const context = useAuthorizedFreightsContext()
  const handlePDF = async () => {
    context.setPDFUId(evidenciaUid, name)
    context.downloadPDF(evidenciaUid, name)
  }

  return (
    <p onClick={ !evidenciaUid ? "" : handlePDF } >
      <strong style={ !evidenciaUid ? {} : { textDecorationLine: 'underline' }}>
        { !evidenciaUid ? 'No hay evidencia' : 'Descargar'}
      </strong>
    </p>
  )
}

export { DownloadPDFButton }
