import React from 'react'
import { PlantsDropdown } from '../../../../PlantsDropdown'
import {
  useAuthorizedFreightsContext
} from '../../../../../contexts/AuthorizedFreightsContext'

const Origin = () => {
  const context = useAuthorizedFreightsContext()

  return (
    <div className='form-field'>
      <label>Seleccione origen:</label>
      { context.isInternal ? 
        <PlantsDropdown
          value={ context.clientId }
          setSelectedPlant={ context.setOriginId }
        /> :
        <PlantsDropdown
          value={ context.originId }
          setSelectedPlant={ context.setOriginId }
        />
      }
    </div>
  )
}

export { Origin }
