import React from 'react'
import { useTanksContext } from '../../../../contexts/TanksContext'
import { createInputValueHandler } from '../../../../helpers/input'
import { Input } from 'semantic-ui-react'

const Model = () => {
  const context = useTanksContext()

  const InputHandler = createInputValueHandler(context.setModel)

  return (
    <div className='form-field'>
      <label>Modelo:</label>
      <Input
        type='number'
        value={ context.model }
        onChange={ InputHandler }
      />
    </div>
  )
}

export { Model }
