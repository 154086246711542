import React from 'react'
import { Input } from 'semantic-ui-react'
import {
  useOrderAssignationContext
} from '../../../contexts/OrderAssignationContext'

const DateAssignToCargoInput = () => {
  const context = useOrderAssignationContext()

  const handleDate = (_, { value }) => {
    context.setFechaAsignadaACarga(value)
    context.setEditOffset(true)
  }
  return (
    <div className="form-field">
      <label>Fecha asignada a carga:</label>
      <Input
        placeholder="Fecha asignada a carga"
        type="datetime-local"
        value={ context.fechaAsignadaACarga }
        onChange={ handleDate }
      />
    </div>
  )
}

export { DateAssignToCargoInput }
