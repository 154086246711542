import React, { useState } from 'react'
import { StyledTable } from '../StyledTable'
import { useOrdersInTransitContext } from '../../contexts/OrdersInTransitContext'
import { filterdata } from '../../utils/common'
import { isSuperadmin } from '../../helpers/sessionCredentials'

const headers = [
  { path: 'turno',                 title: 'Turno' },
  { path: 'folio',                 title: 'Folio' },
  { path: 'cliente',               title: 'Cliente' },
  { path: 'contacto',              title: 'Contacto' },
  { path: 'remision',              title: 'Remisión' },
  { path: 'producto',              title: 'Producto' },
  { path: 'cantidad',              title: 'Cant Prog' },
  { path: 'cantidadReal',          title: 'Cant Real' },
  { path: 'unidadMedida',          title: 'U. M' },
  { path: 'domicilio',             title: 'Domicilio' },
  { path: 'transportista',         title: 'Transportista' },
  { path: 'operador',              title: 'Operador' },
  { path: 'economico',             title: 'Ecónomico' },
  { path: 'placasTanque',          title: 'Placa TQ1'},
  { path: 'placasTanqueDos',       title: 'Placa TQ2'},
  { path: 'celularOperador',       title: 'Cel Operador' },
  { path: 'programado',            title: 'Prog' },
  { path: 'estado',                title: 'Estatus de OV' },
  { path: 'fechaRequerida',        title: 'Fecha requerida' },
  { path: 'fechaCarga',            title: 'Fecha planeada de carga' },
  { path: 'fechaAsignadaACarga',   title: 'Fecha asignada a carga' },
  { path: 'fechaCompromiso',       title: 'Fecha planeada de entrega' },
  { path: 'fechaEntregaReal',      title: 'Fecha de entrega real' }
]

const adminHeaders = [
  { path: 'fechaSolicitud',       title: 'Fecha solicitud de OV' },
  { path: 'fechaRegistro',        title: 'Fecha registro' },
  { path: 'fechaSalidaRefineria', title: 'Fecha de salida de la refinería' },
  { path: 'fechaEntradaPlanta',   title: 'Fecha de entrada a la planta' },
  { path: 'fechaSalidaPlanta',    title: 'Fecha de salida de la planta' },
  { path: 'kmsTraslado',          title: 'Kms traslado' },
  { path: 'hrsTraslado',          title: 'Hrs traslado' }
]

const searchBy = [
  'turno',
  'folio',
  'remision',
  'producto',
  'transportista',
  'economico'
]

const getHeaders = () => {
  if (isSuperadmin()) return [...headers, ...adminHeaders]
  return headers
}

const Table = () => {
  const context = useOrdersInTransitContext()
  const [reloadDataOfTable, setReloadDataOfTable] = useState(false)

  const handleOrdingData = (data, e) => {
    context.setOrders(filterdata(data, e, reloadDataOfTable))
    setReloadDataOfTable(!reloadDataOfTable)
  }

  const handleOnClickRow = order => context.onClickRow(order)

  const getDomicilio = order => {
    if (order?.domicilio?.length > 80)
      return order?.domicilio?.substring(0, 77) + '...'
    return order?.domicilio
  }

  const getFormatedOrders = () => (
    context.orders.map(o => ({
      ...o,
      domicilio: getDomicilio(o),
      estado: o.borrado ? 'Cancelado' : o.estado
    }))
  )
  return (
    <>
      <small>Pedidos: { context.orders.length }</small>
      <div className='table'>
        <StyledTable
          headers={ getHeaders() }
          searchBy={ searchBy }
          data={ getFormatedOrders() }
          handleOnClickRow={ handleOnClickRow }
          handleOnClickHeader={ e => handleOrdingData(context.orders, e) }
        />
      </div>
    </>
  )
}

export { Table }
