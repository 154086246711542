import React from 'react'
import { Button, Input } from 'semantic-ui-react'
import {
  useAuthorizedFreightsContext
} from '../../../../contexts/AuthorizedFreightsContext'

const UploadPDFButton = () => {
  const context = useAuthorizedFreightsContext()

  const inputHandler = e => {
    context.preparePDFBase64(e.target.files[0]) 
    context.shortenPdfName(e.target.files[0], 12)
  }

  const handleRemove = () => {
    context.removePdfFile()
  }

  return (
    <div className='pdf-button'>
      <label htmlFor='c-pdf'>{ context.havePdf ? context.shortPdfName : 'Seleccionar' }</label>
      <Input
        id='c-pdf'
        type='file'
        ref={context.fileInputRef}
        onChange={ inputHandler }
        accept='application/pdf'
      />
      { context.havePdf && !context.isEditing ? 
        <Button negative onClick={handleRemove}
        >Eliminar</Button> : ""
      }
    </div>
  )
}

export { UploadPDFButton }
