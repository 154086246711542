import React, { useEffect } from 'react'
import { Dropdown } from 'semantic-ui-react'
import { useVehicleContext } from '../../../contexts/vehicleContext'
import { createInputValueHandler } from '../../../helpers/input'

const TanksPlates = () => {
  const context = useVehicleContext()

  const tankOneHandler = createInputValueHandler(context.setTankOneId)
  const tankTwoHandler = createInputValueHandler(context.setTankTwoId)

  const differentToTankOne = tank => tank.key !== context.tankOneId
  const differentToTankTwo = tank => tank.key !== context.tankTwoId

  const filterNoSelected = (tanks, evaluator) => {
    const noSelectedTanks = tanks.filter(evaluator)
    const tanksByCompany = filterTanksCompany(noSelectedTanks)
    return context.toDropdown(tanksByCompany)
  }

  const filterTanksCompany = tanks =>
    tanks.filter(tank => (tank.transportistaId === context.shipmentCompany || tank.global === 1))

  const restartTanksInfo = () => {
    context.setTankOneId('')
    context.setTankTwoId('')
  }

  useEffect(() => {
    restartTanksInfo()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context.shipmentCompany])

  return (
    <div className='tanks-dropdowns'>
      <Dropdown
        selection
        search
        value={ context.tankOneId }
        options={ filterNoSelected(context.tanksOne, differentToTankTwo) }
        onChange={ tankOneHandler }
        placeholder='Tanque 1'
      />
      <Dropdown
        selection
        search
        clearable
        value={ context.tankTwoId }
        options={ filterNoSelected(context.tanksTwo, differentToTankOne) }
        onChange={ tankTwoHandler }
        placeholder='Tanque 2'
      />
    </div>
  )
}

export { TanksPlates }
