import React, { createContext, useContext, useEffect, useState, useRef } from 'react'
import { AuthProvider } from './authContext'
import { useSelector } from 'react-redux'
import { hasError, showErrorAlert, showOkAlert } from '../helpers/notifications'
import {
  clients,
  geofenceDestinations,
  federativeEntities,
  saveFreight,
  getFreightData,
  getFreightById,
  updateFreight,
  deleteFreight,
  getPDF,
} from '../services/authorizedFreights'
import { dateNow } from '../helpers/date'
import { fromBase64ToBuffer } from '../helpers/encoder'

const AuthorizedFreightsContext = createContext()

const AuthorizedFreightsProvider = ({ children }) => {

  const { credentials } = useSelector((state) => state.auth)
  const [token,              setToken              ] = useState('')
  const [isEditing,          setIsEditing          ] = useState(false)
  const [isLoading,          setIsLoading          ] = useState(true)
  const [isWorking,          setIsWorking          ] = useState(false)
  const [showConfirmDestroy, setShowConfirmDestroy ] = useState(false)
  const [isInternal,         setIsInternal         ] = useState(false)
  const [clientId,           setClientId           ] = useState('')
  const [clientData,         setClientData         ] = useState([])
  const [freightName,        setFreightName        ] = useState('')
  const [originId,           setOriginId           ] = useState('')
  const [destinationId,      setDestinationId      ] = useState('')
  const [destinationData,    setDestinationData    ] = useState([])
  const [stateId,            setStateId            ] = useState('')
  const [stateData,          setStateData          ] = useState([])
  const [pdf,                setPDF                ] = useState('')
  const [havePdf,            setHavePdf            ] = useState(false)
  const [PDFUId,             setPDFUId             ] = useState('')
  const [shortPdfName,       setShortPdfName       ] = useState('')
  const [tolls,              setTolls              ] = useState('')
  const [authorizedFreight,  setAuthorizedFreight  ] = useState('')
  const [distance,           setDistance           ] = useState('')
  const [authorizedDate,     setAuthorizedDate     ] = useState('')
  const [expirationDate,     setExpirationDate     ] = useState('')
  const [currentFreightId,   setCurrentFreightId   ] = useState('')
  const [freightsData,       setFreightsData       ] = useState([])
  const [validFreightsData,  setValidFreightsData  ] = useState([])
  const [onlyExpired,        setOnlyExpired        ] = useState(false)
  const excelEndpoint = `/authorizedFreights/downloadExcel`

  const fileInputRef = useRef(null)

  useEffect(() => {
    if (credentials) load()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [credentials])

  useEffect(() => {
    if (clientId) loadDestinies()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientId])

  const load = async () => {
    setToken(credentials.token)
    loadFreights()
    loadClients()
    loadStates()
    setIsLoading(false)
  }

  const loadClients = async () => (
    setClientData(await clients(credentials.token))
  )

  const loadDestinies = async () => (
    setDestinationData(await geofenceDestinations(token, clientId))
  )

  const loadStates = async () => (
    setStateData(await federativeEntities(credentials.token))
  )

  const getActiveFreights = freights => (
    freights.filter(f => f.vigencia >= dateNow())
  )

  const loadFreights = async () => {
    const freights = await getFreightData(credentials.token)
    setFreightsData(freights)
    setValidFreightsData(getActiveFreights(freights))
  }

  const clean = () => {
    setIsEditing(false)
    setClientId('')
    setFreightName('')
    setOriginId('')
    setDestinationId('')
    setDestinationData([])
    setStateId('')
    setPDF('')
    setHavePdf(false)
    setTolls(0)
    setAuthorizedFreight(0)
    setDistance(0)
    setAuthorizedDate('')
    setExpirationDate('')
    setIsInternal(false)
    setIsWorking(false)
  }

  const isExpired = () => new Date(context.expirationDate) < new Date()

  const preparePDFBase64 = pdf => {
    if (!pdf) return
    const reader = new FileReader()
    reader.onload = () => setPDF(reader.result)
    reader.readAsDataURL(pdf)
    setHavePdf(true)
  }

  const shortenPdfName = (pdf, maxLength, editing) => {
    if (!pdf) return
    let pdfName
    if (editing) {
      pdfName = pdf
    } else {
      pdfName = pdf.name
    }
    if (pdfName.length <= maxLength) {
      setShortPdfName(pdfName)
      return
    }
    const startLength = Math.floor((maxLength - 3) / 2)
    const endLength = Math.floor((maxLength - 3) / 2)
    const shorten = pdfName.slice(0, startLength) + '...' + pdfName.slice(-endLength)
    setShortPdfName(shorten)
  }

  const removePdfFile = () => {
    setPDF('')
    setShortPdfName('')
    setHavePdf(false)
    fileInputRef.current.inputRef.current.value = ''
  }

  const hasEmptyFields = () => (
    (!isInternal && !originId) ||
    !clientId          ||
    !freightName       ||
    !stateId           ||
    !tolls             ||
    !authorizedFreight ||
    !distance          ||
    !authorizedDate    ||
    !expirationDate    ||
    !pdf
  )

  const getDestinationId = () => {
    return destinationId
  }

  const getOriginId = () => {
    if (isInternal) return clientId
    return originId
  }

  const prepareParams = () => ({
    clienteId:           clientId,
    nombre:              freightName,
    origenPlantaId:      getOriginId(),
    esExterno:           !isInternal,
    entidadFederativaId: stateId,
    costoCasetas:        tolls,
    costoTonelada:       authorizedFreight,
    distancia:           distance,
    fechaAutorizada:     authorizedDate,
    vigencia:            expirationDate,
    destinoClienteId:    getDestinationId(),
    evidencia:           havePdf ? fromBase64ToBuffer(pdf) : null
  })

  const handleResponse = data => {
    if (hasError(data)) return showErrorAlert(data.message)
    clean()
    loadFreights()
    showOkAlert(data.message)
  }

  const save = () => {
    if (hasEmptyFields())
      return showErrorAlert('Completa los campos faltantes.')
    if (isExpired())
      return showErrorAlert('No puedes registrar un flete vencido')
    setIsWorking(true)
    saveFreight(prepareParams(), token)
      .then(handleResponse)
      .catch(error => showErrorAlert(error.message))
      .finally(() => setIsWorking(false))
  }

  const fillFields = freight => {
    setClientId(freight.clienteId)
    setIsInternal(!freight.esExterno)
    setFreightName(freight.nombre)
    setOriginId(freight.origenPlantaId)
    setDestinationId(freight.destinoClienteId)
    setStateId(freight.entidadFederativaId)
    setTolls(freight.costoCasetas)
    setAuthorizedFreight(freight.costoTonelada)
    setDistance(freight.distancia)
    setAuthorizedDate(freight.fechaAutorizada)
    setExpirationDate(freight.vigencia)

    if (freight.evidenciaAutorizacionUid !== null) {
      setHavePdf(true)
      shortenPdfName(freight.evidenciaAutorizacionUid, 12, true)
    } else {
      removePdfFile()
    }
  }

  const handleFillFields = data => {
    if (hasError(data)) return showErrorAlert(data.message)
    fillFields(data)
  }

  const onSelectRow = freight => {
    setIsEditing(true)
    setIsWorking(true)
    setCurrentFreightId(freight.id)
    getFreightById(freight.id, token)
      .then(handleFillFields)
      .catch(error => showErrorAlert(error.message))
      .finally(() => setIsWorking(false))
  }

  const update = () => {
    if (hasEmptyFields()) return showErrorAlert('Completa los campos faltantes.')
    setIsWorking(true)
    updateFreight(currentFreightId, prepareParams(), token)
      .then(handleResponse)
      .catch(error => showErrorAlert(error.message))
      .finally(() => setIsWorking(false))
  }

  const destroy = async () => {
    setIsWorking(true)
    deleteFreight(currentFreightId, token)
      .then(handleResponse)
      .catch(error => showErrorAlert(error.message))
      .finally(() => clean())
  }

  const generateDownload = (file, name) => {
    const filename  = `evidencia_autorización_${name}.pdf`
    const blob      = new Blob([file])
    const windowUrl = window.URL.createObjectURL(blob)
    const link      = document.createElement('a')
    link.href       = windowUrl
    link.setAttribute('download', filename)
    document.body.appendChild(link)
    link.click()
    link.parentNode.removeChild(link)
  }

  const downloadPDF = (uid, name) => {
    getPDF(uid, token)
      .then(response => generateDownload(response, name))
      .catch(error => showErrorAlert(error.message))
      .finally(() => setPDFUId(0))
  }

  const context = {
    isLoading,
    isEditing,
    isWorking,
    showConfirmDestroy,
    isInternal,
    clientId,
    clientData,
    freightName,
    originId,
    destinationId,
    destinationData,
    stateId,
    stateData,
    pdf,
    havePdf,
    PDFUId,
    shortPdfName,
    tolls,
    authorizedFreight,
    distance,
    authorizedDate,
    expirationDate,
    freightsData,
    validFreightsData,
    onlyExpired,
    excelEndpoint,
    fileInputRef,
    setShowConfirmDestroy,
    setIsInternal,
    setClientId,
    setFreightName,
    setOriginId,
    setDestinationId,
    setStateId,
    preparePDFBase64,
    setTolls,
    setAuthorizedFreight,
    setDistance,
    setAuthorizedDate,
    setExpirationDate,
    setPDFUId,
    setShortPdfName,
    update,
    save,
    onSelectRow,
    destroy,
    clean,
    setFreightsData,
    setValidFreightsData,
    setOnlyExpired,
    downloadPDF,
    removePdfFile,
    shortenPdfName
  }

  return (
    <AuthProvider>
      <AuthorizedFreightsContext.Provider value={ context }>
        { children }
      </AuthorizedFreightsContext.Provider>
    </AuthProvider>
  )
}

const useAuthorizedFreightsContext = () => useContext(AuthorizedFreightsContext)

export { AuthorizedFreightsProvider, useAuthorizedFreightsContext }
