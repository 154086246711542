import React from 'react'
import {
  useOrdersInTransitContext
} from '../../../../contexts/OrdersInTransitContext'

const DateAssignToCargoLabel = () => {
  const context = useOrdersInTransitContext()
  const getLabel = () => (
    context.orderDetails.info.fechaAsignadaACarga || 'Sin asignar'
  )

  return (
    <div>
      <div>Fecha asignada a carga:</div>
      <div>{ getLabel() }</div>
    </div>
  )
}

export { DateAssignToCargoLabel }
