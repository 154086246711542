import React from 'react'
import { useAuthorizedFreightsContext } from '../../contexts/AuthorizedFreightsContext';
import { ExcelButton } from '../ExcelButton';

const DownloadButtons = () => {
  const context = useAuthorizedFreightsContext()

  return (
    <div className='download-container'>
      {
        context.freightsData?.length ? (
          <ExcelButton
            endpoint={ context.excelEndpoint }
            fileName='fletes_autorizados'
          />
        ) : (<></>)
      }
    </div>
  )
}

export { DownloadButtons }