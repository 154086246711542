import React, { useState } from 'react'
import { StyledTable } from '../../../StyledTable'
import { filterdata } from '../../../../utils/common'
import {
  useAuthorizedFreightsContext
} from '../../../../contexts/AuthorizedFreightsContext'
import { DownloadPDFButton } from '../actions/DownloadPDFButton'

const headers = [
  { path: 'nombre',          title: 'Nombre Flete'          },
  { path: 'origen',          title: 'Origen'                },
  { path: 'destino',         title: 'Destino'               },
  { path: 'estado',          title: 'Estado'                },
  { path: 'evidenciaUid',    title: 'Evidencia'             },
  { path: 'costoCasetas',    title: 'Casetas ($)'           },
  { path: 'costoTonelada',   title: 'Flete x Ton ($)'       },
  { path: 'fechaAutorizada', title: 'Fecha de autorización' },
  { path: 'vigencia',        title: 'Fecha de vigencia'     }
]

const toSearch = headers.map(h => h.path)
const headerSearchBy = (
  headers.filter(h => h.title !== 'Evidencia').map(h => h.title)
)

const Table = () => {
  const context = useAuthorizedFreightsContext()
  const [reloadData, setReloadData] = useState(false)

  const handleData = () => {
    if (context.onlyExpired) return context.validFreightsData
    return context.freightsData
  }

  const buildData = () => handleData().map(e => ({
    ...e,
    evidenciaUid: renderButton(e.evidenciaUid, e.nombre)
  }))

  const isWaitingToDownload = uid => context.PDFUId === uid

  const renderButton = (uid, name) => {
    if (isWaitingToDownload(uid)) return 'Descargando...'
    return <DownloadPDFButton evidenciaUid={ uid } name={ name }/>
  }

  const handleSort = e => {
    if (context.onlyExpired) {
      const filteredData = filterdata(handleData, e, reloadData)
      return context.setValidFreightsData(filteredData)
    }
    else context.setFreightsData(filterdata(handleData, e, reloadData))
    setReloadData(!reloadData)
  }

  return (
    <div className='table-container'>
      <StyledTable
        search
        headerSearchBy={ headerSearchBy }
        data={ buildData() }
        headers={ headers }
        searchBy={ toSearch }
        handleOnClickHeader={ handleSort }
        handleOnClickRow={ context.onSelectRow }
      />
    </div>
  )
}

export { Table }
