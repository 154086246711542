import React from 'react'
import { Dropdown } from 'semantic-ui-react'
import {
  useAuthorizedFreightsContext
} from '../../../../../contexts/AuthorizedFreightsContext'
import { createInputValueHandler } from '../../../../../helpers/input'
import { PlantsDropdown } from '../../../../PlantsDropdown'

const Destination = () => {
  const context = useAuthorizedFreightsContext()

  const InputHandler = createInputValueHandler(context.setDestinationId)

  return (
    <div className='form-field'>
      <label>Seleccione destino:</label>
      {
        context.isInternal ?
          <PlantsDropdown
            value={ context.destinationId }
            setSelectedPlant={ context.setDestinationId }
            className='value'
          /> :
          <Dropdown
            search
            selection
            value={ context.destinationId }
            options={ context.destinationData }
            onChange={ InputHandler }
            className='value'
            placeholder='Seleccione destino...'
          />
      }
    </div>
  )
}

export { Destination }
